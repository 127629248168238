/**
 * Webkit support for remote debugging
 * @return {[type]} [description]
 */
function wkSelector() {
  var oldMatches = Element.prototype.webkitMatchesSelector

  function newMatches(selector) {
    try {
      return oldMatches.call(this, selector)
    } catch (err) {
      return false
    }
  }

  Element.prototype.webkitMatchesSelector = newMatches
}

wkSelector();
/*test*/ 
